<template>
    <v-container fluid class="ma-0 pa-0"> 
        
        <!-- DESKTOP -->
        <v-expand-x-transition>
            <v-img v-if="false" class="d-none d-lg-block" width="70px" v-show="showLateral" style="z-index: 100; position: fixed; right: 0; top: 20%;" src="@/assets/covers/laterais.svg"></v-img>     
        </v-expand-x-transition>
        <!-- MOBILE -->
        <v-expand-x-transition>
            <v-img class="d-lg-none" width="25px" v-show="showLateral" style="z-index: 100; position: fixed; right: 0; top: 70%;" src="@/assets/covers/laterais.svg"></v-img>     
        </v-expand-x-transition>
        <Intro />

        <v-container fluid class="d-none d-lg-block transparent secondary py-12"></v-container>

        <TheTeam class="py-16"/>


        <v-container fluid class="transparent ma-0 pa-0">
            <v-container fluid class="transparent accent ma-0 pa-0">
                <v-row class="ma-0 pa-0 d-none d-lg-block gradient-plan" style="background-color: #CBD3D4;">
                   
                </v-row>
            </v-container>
        </v-container>

        <YouArrived id="utility"/>

        <!-- PORTFOLIO SECTION -->
        <Portfolio id="portfolio"/>

        <v-container fluid class="transparent ma-0 pa-0">
            <v-container fluid class="transparent white ma-0 pa-0">
                <v-row class="ma-0 pa-0 d-none d-lg-block gradient-plan" style="background-color: #9db5c9;">
                    
                </v-row>
            </v-container>
        </v-container>

        <!-- Testimonials -->
        <Testimonials />

        <!-- TECNOLOGIAS -->
        <Technologies id="technologies" class="py-16"/>

        <FAQ id="faq" class="py-16"/>

        <PreFooter />

        
    </v-container>
</template>
<style>
.test{
    background: url('../assets/covers/setas.svg') no-repeat;
    background-size: cover;
    background-size: 100%;
}

.line-1 {
  height: 2px;
  background: black;
  width: 50%;
  margin: auto;
}

@media (min-width: 850px) {
    .gradient-plan{
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);
        height: 150px;
    }
}


</style>
<script>
import Intro from "@/views/Intro.vue"
import Technologies from "@/views/Technologies.vue"
import Portfolio from "@/views/Portfolio.vue"
import YouArrived from "@/views/YouArrived.vue"
import TheTeam from "@/views/TheTeam.vue"
import Testimonials from "@/views/Testimonials.vue"
import PreFooter from "@/views/PreFooter.vue"
import FAQ from "@/views/FAQ.vue"

export default{
    watch:{
        '$route.params.place':{
            immediate: true,
            handler(val){
                if(val == undefined)
                    return

                let ob = document.getElementById(val)
                if(ob != null)
                    ob.scrollIntoView({behavior: 'smooth'});
            }
        },
    },
    components:{
        Intro,
        Technologies,
        Portfolio,
        YouArrived,
        TheTeam,
        Testimonials,
        PreFooter,
        FAQ
    },
    data(){
        return{
            showLateral: false
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted(){
        if(this.$route.params.place != undefined){
            let ob = document.getElementById(this.$route.params.place)
            ob.scrollIntoView({behavior: 'smooth'});
          }
    },
    methods:{
        handleScroll () {
            // Any code to be executed when the window is scrolled
            if(window.scrollY > 370)
                this.showLateral = true
            else this.showLateral = false
        },
        emailSender(){
            window.location.href = "mailto:info@dreamdealtech.pt?subject=&body=";
        },
    }
}
</script>