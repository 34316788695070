<template>
    <v-container class="mt-n12" fluid style="background: linear-gradient(#052947, 30%, #587084, #9db5c9)">
        <!-- DESKTOP -->
        <v-row class="d-none d-lg-block mx-16 px-16 py-16">
            <v-col cols="12" class="text-left">
                <v-img v-if="false" cover width="70%" style="display: block; margin: auto;" src="@/assets/texts/portfolio.svg"></v-img>
                <span class="white--text text-h1 font-weight-bold" style="display:block; margin: auto; font-family: Montserrat !important;">{{$t('portfolio.title')}}</span>
            </v-col>
        </v-row>

        <!-- MOBILE -->
        <v-row class="d-lg-none">
            <v-col cols="12" class="text-center">
                <v-img v-if="false" cover style="display: block; margin: auto;" src="@/assets/texts/portfolio.svg"></v-img>
                <span class="text-h4 white--text font-weight-bold" style="display:block; margin: auto; font-family: Montserrat !important;">{{$t('portfolio.title')}}</span>
            </v-col>
        </v-row>

        <!-- DESKTOP -->
        <div class="d-none d-lg-block"  >
            <v-row no-gutters justify="center" class="py-16">
                <v-col cols="4" class="mx-6 px-6">
                    <v-card class="rounded-xl">
                        <v-img src="@/assets/portfolio/reportlake.jpg" height="250px" contain class="mx-4"></v-img>
                    </v-card>
                    <v-card-text class="text-h5 font-weight-light white--text">
                        {{$t('portfolio.reportlake.txt1')}}
                    </v-card-text>
                    <v-card-text class="text-h5 font-weight-light white--text" v-html="$t('portfolio.reportlake.txt2')">
                    </v-card-text>
                </v-col>
                <v-col cols="4" class="mx-6 px-6">
                    <v-card class="rounded-xl">
                        <v-img src="@/assets/portfolio/atlas.png" height="250px" contain class="mx-4"></v-img>
                    </v-card>
                    <v-card-text class="text-h5 font-weight-light white--text">
                        {{$t('portfolio.atlas')}}
                    </v-card-text>
                </v-col>
                <v-col cols="4" class="mx-6 px-6">
                    <v-card class="rounded-xl black">
                        <v-img src="@/assets/portfolio/ignoto_branco.png" height="250px" contain class="mx-4"></v-img>
                    </v-card>
                    <v-card-text class="text-h5 font-weight-light white--text" v-html="$t('portfolio.ignoto.txt1')">
                        
                    </v-card-text>
                    <v-card-text class="text-h5 font-weight-light white--text" v-html="$t('portfolio.ignoto.txt2')">
                    </v-card-text>
                </v-col>
            </v-row>
        </div>

        <!-- MOBILE -->
        <v-row no-gutters justify="center" class="d-lg-none">
            <v-col cols="12">
                <v-card class="rounded-xl mt-8">
                    <v-img src="@/assets/portfolio/reportlake.jpg" contain class="mx-2"></v-img>
                </v-card>
                <v-card-text class="font-weight-medium white--text">
                    {{$t('portfolio.reportlake.txt1')}}
                </v-card-text>
                <v-card-text class="text-center font-weight-medium white--text" v-html="$t('portfolio.reportlake.txt2')">
                    
                </v-card-text>
            </v-col>
            <v-col cols="12">
                <v-card class="rounded-xl mt-8">
                    <v-img src="@/assets/portfolio/atlas.png" contain class="mx-2"></v-img>
                </v-card>
                <v-card-text class="font-weight-medium white--text">
                    {{$t('portfolio.atlas')}}
                </v-card-text>
            </v-col>
            <v-col cols="12">
                <v-card class="rounded-xl mt-8 black">
                    <v-img src="@/assets/portfolio/ignoto_branco.png" contain class="mx-2"></v-img>
                </v-card>
                <v-card-text class="font-weight-medium white--text" v-html="$t('portfolio.ignoto.txt1')">
                </v-card-text>
                <v-card-text class="text-center font-weight-medium white--text" v-html="$t('portfolio.ignoto.txt2')">
                    
                </v-card-text>
            </v-col>
        </v-row>

        <!-- DESKTOP -->
        <v-row class="d-none d-lg-block mx-16 px-16 py-16">
            <v-col cols="12">
                <span style="font-family: Montserrat !important;" class="white--text text-h1 font-weight-bold">{{$t('portfolio.partners.title')}}</span>
            </v-col>
        </v-row>
        <!-- MOBILE -->
        <v-row class="d-lg-none">
            <v-col cols="12" class="text-center">
                <span style="font-family: Montserrat !important;" class="white--text text-h4 font-weight-bold">{{$t('portfolio.partners.title')}}</span>
            </v-col>
        </v-row>

        <!-- DESKTOP -->
        <div class="d-none d-lg-block">
            <v-row no-gutters justify="center">
                <v-col cols="2" class="ma-12" v-for="item in images" :key="item">
                    <v-card class="rounded-xl transparent elevation-0">
                        <v-img v-if="item.link == null" :src="imagePath(item.path)" height="250px" contain></v-img>
                        <v-img v-else style="cursor: pointer;" @click="connectPartner(item.link)" :src="imagePath(item.path)" height="250px" contain></v-img>
                    </v-card>
                </v-col>                
            </v-row>
            
        </div>

        <!-- MOBILE -->
        <v-row class="ma-0 pa-0 d-lg-none">           
            <v-col cols="12">
                <carousel-3d ref="my-carousel-portfolio" :autoplay="true" :autoplay-timeout="3000" class="ma-0 pa-0" :width="300" :height="310" :perspective="75" :disable3d="false" :display="3" :space="365" :controls-visible="false">
                    <slide v-for="(i, index) in images" :index="index" :key="index" class="transparent elevation-0">
                        <v-container fluid class="fill-height white rounded-xl elevation-0">
                            <v-img 
                                :src="imagePath(i.path)"
                                contain
                            ></v-img>
                        </v-container>
                    </slide>                    
                </carousel-3d>
            </v-col>
            <v-col cols="12" class="mt-n12 text-center ma-0 pa-0 mb-8">
                <v-btn
                    class="mx-6"
                    fab
                    dark
                    small
                    color="primary"
                    @click="goLeft()"
                    >
                    <v-icon dark color="white">
                        mdi-arrow-left-thick
                    </v-icon>
                </v-btn>
                
                <v-btn
                    class="mx-6"
                    fab
                    dark
                    small
                    color="primary"
                    @click="goRight()"
                    >
                    <v-icon dark color="white">
                        mdi-arrow-right-thick
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>


        <!-- DESKTOP -->
        <v-row class="d-none d-lg-block mx-16 px-16 py-16" id="team">
            <v-col cols="12" class="text-left">
                <v-img v-if="false" cover width="70%" style="display: block; margin: auto;" src="@/assets/texts/portfolio.svg"></v-img>
                <span class="white--text text-h1 font-weight-bold" style="display:block; margin: auto; font-family: Montserrat !important;">{{$t('team.meet')}}</span>
            </v-col>
        </v-row>

        <!-- MOBILE -->
        <v-row class="d-lg-none" id="team2">
            <v-col cols="12" class="text-center">
                <v-img v-if="false" cover style="display: block; margin: auto;" src="@/assets/texts/portfolio.svg"></v-img>
                <span class="text-h4 white--text font-weight-bold" style="display:block; margin: auto; font-family: Montserrat !important;">{{$t('team.meet')}}</span>
            </v-col>
        </v-row>

        <!-- DESKTOP -->
        <v-row class="d-none d-lg-block mt-16 mx-16 px-16">
            <v-col cols="12" md="12" >
                <v-row no-gutters justify="center" class="py-16 ">
                    <v-col cols="auto">
                        <v-avatar
                            style="margin: auto; display: block;"
                            size="250px"
                            class="elevation-16"
                        >
                            <img
                                alt="Avatar"
                                src="@/assets/team/alvaro_new.png"
                            >
                        </v-avatar>
                    </v-col>
                    <v-col cols="8" class="ml-8 text-justify text-h5 white--text font-weight-bold" style="font-family: Montserrat !important;">
                        <span class="font-italic">{{$t('team.alvaro.text')}}</span><br/><br/>
                        <span class="font-weight-bold" style="color: #052A59; font-family: Montserrat !important; font-weight: bold !important;">{{$t('team.alvaro.presentation')}}</span>
                    </v-col>

                </v-row>
            </v-col>

            <v-col cols="12" md="12" class="mt-16 py-2">
                <v-row no-gutters justify="center">
                    <v-col cols="8" class="ml-8 text-justify text-h5 white--text font-weight-bold " style="font-family: Montserrat !important;">
                        <span class="font-italic">{{$t('team.erbi.text')}}</span><br/><br/>
                        <span class="font-weight-bold" style="color: #052A59; font-family: Montserrat !important; font-weight: bold !important;">{{$t('team.erbi.presentation')}}</span>
                    </v-col>
                    <v-col cols="auto" class="ml-8">
                        <v-avatar
                            style="margin: auto; display: block;"
                            size="250px"
                            class="elevation-16"
                        >
                            <img
                                alt="Avatar"
                                src="@/assets/team/erbi.jpeg"
                            >
                        </v-avatar>
                    </v-col>

                </v-row>
            </v-col>

            <v-col cols="12" md="12" >
                <v-row no-gutters justify="center" class="py-16 ">
                    <v-col cols="auto">
                        <v-avatar
                            style="margin: auto; display: block;"
                            size="250px"
                            class="elevation-16"
                        >
                            <img
                                alt="Avatar"
                                src="@/assets/team/tiago.jpg"
                            >
                        </v-avatar>
                    </v-col>
                    <v-col cols="8" class="ml-8 text-justify text-h5 white--text font-weight-bold" style="font-family: Montserrat !important;">
                        <span class="font-italic">{{$t('team.tiago.text')}}</span><br/><br/>
                        <span class="font-weight-bold" style="color: #052A59; font-family: Montserrat !important; font-weight: bold !important;">{{$t('team.tiago.presentation')}}</span>
                    </v-col>

                </v-row>
            </v-col>
        </v-row>

        <!-- MOBILE -->
        <v-row justify="center" no-gutters class="d-lg-none ma-2 pa-2">
            <v-col cols="auto">
                <v-img v-if="false" width="100%" style="margin: auto; display: block;" src="@/assets/texts/meet the team.svg"></v-img>
            </v-col>
            <v-col cols="12" class="mt-16 text-justify text-body-1 white--text font-italic" style="font-family: Montserrat !important;">
                {{$t('team.alvaro.text')}}
            </v-col>
            <v-col cols="12" class="mt-8 text-center">
                <span class="font-weight-bold" style="color: #052A59; font-family: Montserrat !important; font-weight: black !important;">{{$t('team.alvaro.presentation')}}</span>
            </v-col>
            <v-col cols="12" class="mt-16">
                <v-avatar
                    style="margin: auto; display: block;"
                  size="80px"
                >
                    <img
                        alt="Avatar"
                        src="@/assets/team/alvaro_new.png"
                    >
                </v-avatar>
            </v-col>
        </v-row>
        <v-row justify="center" no-gutters class="d-lg-none ma-2 pa-2">
            <v-col cols="auto">
                <v-img v-if="false" width="100%" style="margin: auto; display: block;" src="@/assets/texts/meet the team.svg"></v-img>
            </v-col>
            <v-col cols="12" class="mt-16 text-justify text-body-1 white--text font-italic" style="font-family: Montserrat !important;">
                {{$t('team.erbi.text')}}
            </v-col>
            <v-col cols="12" class="mt-8 text-center">
                <span class="font-weight-bold" style="color: #052A59; font-family: Montserrat !important; font-weight: black !important;">{{$t('team.erbi.presentation')}}</span>
            </v-col>
            <v-col cols="12" class="mt-16">
                <v-avatar
                    style="margin: auto; display: block;"
                  size="80px"
                >
                    <img
                        alt="Avatar"
                        src="@/assets/team/erbi.jpeg"
                    >
                </v-avatar>
            </v-col>
        </v-row>
        <v-row justify="center" no-gutters class="d-lg-none ma-2 pa-2">
            <v-col cols="auto">
                <v-img v-if="false" width="100%" style="margin: auto; display: block;" src="@/assets/texts/meet the team.svg"></v-img>
            </v-col>
            <v-col cols="12" class="mt-16 text-justify text-body-1 white--text font-italic" style="font-family: Montserrat !important;">
                {{$t('team.tiago.text')}}
            </v-col>
            <v-col cols="12" class="mt-8 text-center">
                <span class="font-weight-bold" style="color: #052A59; font-family: Montserrat !important; font-weight: black !important;">{{$t('team.tiago.presentation')}}</span>
            </v-col>
            <v-col cols="12" class="mt-16">
                <v-avatar
                    style="margin: auto; display: block;"
                  size="80px"
                >
                    <img
                        alt="Avatar"
                        src="@/assets/team/tiago.jpg"
                    >
                </v-avatar>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default{
    data(){
        return{
            images:[
                {
                    path: 'conduril.png',
                    link: null,
                },
                {
                    path: 'quinta_javali.png',
                    link: null,
                },
                {
                    path: 'geonorte.png',
                    link: null,
                },
                {
                    path: 'edirio.png',
                    link: null,
                },
                {
                    path: '1200px-Logo_gifi_2020.png',
                    link: null,
                },
                {
                    path: 'Logo-Esquénio---negro.png',
                    link: null,
                },
                {
                    path: 'logo_conversas-com-pnl-22-TRP.png',
                    link: 'https://conversascompnl.com/',
                }             
            ],
        }
    },
    methods:{
        connectPartner(link){
            window.open(link, '_blank')
        },
        imagePath(item){
            return require("@/assets/portfolio/" + item)
        },
        goTo(place){
            if(place == 'reportLake'){
                window.open('https://www.reportlake.com/', '_blank')
            }
        },
        goLeft(){
            this.$refs['my-carousel-portfolio'].goPrev()
        },
        goRight(){
            this.$refs['my-carousel-portfolio'].goNext()
        }
    }
}
</script>