import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../views/Homepage.vue'
import Career from '../views/Career.vue'
import Blog from '../views/Blog.vue'
import Layout from '../Layout.vue'
import Article from '../components/Blog/Article.vue'
import Success from '../components/Success.vue'
import Contacts from '../views/Contacts.vue'
import Privacy from '../views/Privacy.vue'

import BackendLayout from '@/views/backend/BackendLayout.vue'
import BackendEditor from '@/views/backend/BackendEditor.vue'
import CreateArticle from '@/views/backend/editors/Article.vue'
import EditArticle from '@/views/backend/editors/Article.vue'
import Login from '@/views/backend/Login.vue'
import FAQ from '@/views/FAQ.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/pt'
  },
  {
    path: '/:lang/backend',
    name: 'backend',
    component: BackendLayout,
    children: [
      {
        path: '/:lang/backend/login',
        name:'backend',
        component: Login
      },
      {
        path: '/:lang/backend',
        name:'backend',
        component: BackendEditor
      },
      {
        path: '/:lang/backend',
        name:'CreateArticle',
        component: CreateArticle
      },
      {
        path: '/:lang/backend/:article_id',
        name:'EditArticle',
        component: EditArticle
      }
    ]
  },
  {
    path: '/:lang',
    name: 'home',
    component: Layout,
    children: [
      {
        path: '/:lang',
        component: Homepage
      },
      {
        path: '/:lang/home',
        name:'home',
        component: Homepage
      },
      {
        path: '/:lang/home/:place',
        component: Homepage
      },
      {
        path: '/:lang/career',
        component: Career
      },
      {
        path: '/:lang/blog',
        name: 'Blog',
        component: Blog
      },
      {
        path: '/:lang/blog/article/:id',
        component: Article
      },
      {
        path: '/:lang/success',
        component: Success
      },
      {
        path: '/:lang/faq',
        name: 'FAQ',
        component: FAQ
      },
      {
        path: '/:lang/contacts',
        component: Contacts
      },
      {
        path: '/:lang/privacy',
        component: Privacy,
        name: 'Privacy'
      },
      {
        path: '/article/:id',
        redirect: '/pt/blog/article/:id'
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
