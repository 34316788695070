<template>
    <v-container class="mt-5">
      <v-row justify="center">
        <v-col cols="12" md="10">
          <h2 class="text-center mb-4">{{ $t('FAQs') }}</h2>
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
              <v-expansion-panel-header><strong>{{ faq.pergunta }}</strong></v-expansion-panel-header>
              <v-expansion-panel-content>{{ faq.resposta }}</v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    computed: {
      faqs() {
        return [
          { pergunta: this.$t('FAQ.q1'), resposta: this.$t('FAQ.a1') },
          { pergunta: this.$t('FAQ.q2'), resposta: this.$t('FAQ.a2') },
          { pergunta: this.$t('FAQ.q3'), resposta: this.$t('FAQ.a3') },
          { pergunta: this.$t('FAQ.q4'), resposta: this.$t('FAQ.a4') },
          { pergunta: this.$t('FAQ.q5'), resposta: this.$t('FAQ.a5') },
          { pergunta: this.$t('FAQ.q6'), resposta: this.$t('FAQ.a6') },
          { pergunta: this.$t('FAQ.q7'), resposta: this.$t('FAQ.a7') },
          { pergunta: this.$t('FAQ.q8'), resposta: this.$t('FAQ.a8') },
          { pergunta: this.$t('FAQ.q9'), resposta: this.$t('FAQ.a9') }
        ];
      }
    }
  };
  </script>
  
  <style scoped>
  h2 {
    font-size: 1.8rem;
    font-weight: bold;
  }
  </style>
  